/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 1:07 PM */

@font-face {
	font-family: 'Fira Sans';
	src: url('fonts/firaSans/FiraSans-Italic.eot');
	src: local('Fira Sans Italic'), local('FiraSans-Italic'),
		url('fonts/firaSans/FiraSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('fonts/firaSans/FiraSans-Italic.woff') format('woff'),
		url('fonts/firaSans/FiraSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('fonts/firaSans/FiraSans-Bold.eot');
	src: local('Fira Sans Bold'), local('FiraSans-Bold'),
		url('fonts/firaSans/FiraSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('fonts/firaSans/FiraSans-Bold.woff') format('woff'),
		url('fonts/firaSans/FiraSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('fonts/firaSans/FiraSansMedium-Italic.eot');
	src: local('Fira Sans Medium Italic'), local('FiraSansMedium-Italic'),
		url('fonts/firaSans/FiraSansMedium-Italic.eot?#iefix') format('embedded-opentype'),
		url('fonts/firaSans/FiraSansMedium-Italic.woff') format('woff'),
		url('fonts/firaSans/FiraSansMedium-Italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('fonts/firaSans/FiraSansLight.eot');
	src: local('Fira Sans Light'), local('FiraSansLight'),
		url('fonts/firaSans/FiraSansLight.eot?#iefix') format('embedded-opentype'),
		url('fonts/firaSans/FiraSansLight.woff') format('woff'),
		url('fonts/firaSans/FiraSansLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('fonts/firaSans/FiraSans.eot');
	src: local('Fira Sans'), local('FiraSans'),
		url('fonts/firaSans/FiraSans.eot?#iefix') format('embedded-opentype'),
		url('fonts/firaSans/FiraSans.woff') format('woff'),
		url('fonts/firaSans/FiraSans.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('fonts/firaSans/FiraSansMedium.eot');
	src: local('Fira Sans Medium'), local('FiraSansMedium'),
		url('fonts/firaSans/FiraSansMedium.eot?#iefix') format('embedded-opentype'),
		url('fonts/firaSans/FiraSansMedium.woff') format('woff'),
		url('fonts/firaSans/FiraSansMedium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('fonts/firaSans/FiraSansLight-Italic.eot');
	src: local('Fira Sans Light Italic'), local('FiraSansLight-Italic'),
		url('fonts/firaSans/FiraSansLight-Italic.eot?#iefix') format('embedded-opentype'),
		url('fonts/firaSans/FiraSansLight-Italic.woff') format('woff'),
		url('fonts/firaSans/FiraSansLight-Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('fonts/firaSans/FiraSans-BoldItalic.eot');
	src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
		url('fonts/firaSans/FiraSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('fonts/firaSans/FiraSans-BoldItalic.woff') format('woff'),
		url('fonts/firaSans/FiraSans-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
